import Vue from 'vue/dist/vue.esm.js';
import vSelect from "vue-select";
import axios from 'axios'

const token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers['X-CSRF-TOKEN'] = token

window.onload = function(){
  var searchBox = new Vue({
    el: "#search_box",
    components: {vSelect},
    data: {
      cars: [
        {
          id: null,
          name: null,
          code: null
        }
      ],
      car_sub_categories: [
        {
          id: null,
          name: null
        }
      ],
      selected_brand_id: "",
      selected_car_name: "",
      car_category_id: "",
      car_sub_category_id: "",
      option_area_status: false,
      model_code: "",
      model_codes: []
    },
    mounted: function() {
      self = this
      const urlParams = new URLSearchParams(window.location.search);
      if(urlParams.get('stock_search_form[brand_id]')){
        self.selected_brand_id = urlParams.get('stock_search_form[brand_id]')
      }
      if(urlParams.get('stock_search_form[car_name]')){
        self.selected_car_name = urlParams.get('stock_search_form[car_name]')
      }
      if(urlParams.get('stock_search_form[car_category_id]')){
        self.car_category_id = urlParams.get('stock_search_form[car_category_id]')
      }
      if(urlParams.get('stock_search_form[car_sub_category_id]')){
        self.car_sub_category_id = urlParams.get('stock_search_form[car_sub_category_id]')
      }
      if(urlParams.get('stock_search_form[model_code]')){
        self.model_code = urlParams.get('stock_search_form[model_code]')
      }
      self.fetchCars()
      self.fetchModelCode()
      self.fetchCarSubCategories()
    },
    methods: {
      fetchCars: function(){
        axios.get("/api/v1/cars.json", {params: {brand_id: self.selected_brand_id}})
        .then((response) => {
          this.cars = response.data;
        })
      },
      fetchModelCode: function() {
        axios.get("/api/v1/car_model_codes.json")
        .then((response) => {
          self.model_codes = response.data;
        })
      },
      fetchCarSubCategories: function(){
        axios.get(`/api/v1/car_sub_categories.json`, {params: {car_category_id: self.car_category_id}})
        .then((response) => {
          this.car_sub_categories = response.data;
          this.car_sub_categories.unshift({id: "", name: "Sub Category"}) // 未選択の場合の値を用意する
        })
      },
      moreDetails: function() {
        this.option_area_status = !this.option_area_status
      },
      selectModelCode: function(selectModelCode){
        self.model_code = selectModelCode
      }
    }
  })
}